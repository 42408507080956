<template>
  <div class="main">
    <div class="title">整卷训练</div>
    <el-form :model="ruleForm"
             ref="ruleForm"
             label-width="100px"
             class="demo-ruleForm">
      <el-form-item label="科目"
                    style="width: 20%;"
                    prop="subject_ids">
        <el-select style="width:100%"
                   v-model="ruleForm.subject_ids"
                   clearable
                   @change="changePlace"
                   placeholder="选择科目">
          <el-option v-for="item in subjectList"
                     :key="item.subject_id"
                     :label="item.subject_name"
                     :value="item.subject_id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="地区"
                    style="width: 20%;"
                    prop="paper_district_id">
        <el-select style="width:100%"
                   v-model="ruleForm.paper_district_id"
                   clearable
                   @change="change"
                   placeholder="选择地区">
          <el-option v-for="item in placeList"
                     :key="item.paper_district_id"
                     :label="item.paper_district_name"
                     :value="item.paper_district_id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="试卷"
                    style="width: 20%;"
                    prop="source_id">
        <el-select style="width:100%"
                   v-model="ruleForm.source_id"
                   clearable
                   multiple
                   filterable
                   @change="changePaper"
                   placeholder="选择试卷">
          <el-option v-for="(item,index) in paperList"
                     :key="item.source_id+index+item.source_name"
                     :label="item.source_name"
                     :value="item.source_id+item.source_name">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary"
                   @click="submitForm('ruleForm')">确定</el-button>
        <el-button @click="goback()">取消</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { getSourceList, getDetectionList, postDoPaper, getPaperDistricts } from '@/api/dataAnalysis.js'
export default {
  data () {
    return {
      ruleForm: {
        subject_ids: '',
        paper_district_id: '',
        source_id: ''
      },
      // subject_ids: '',
      rules: {
        paper_district_id: [
          { required: true, message: '请选择地区', trigger: 'change' }
        ],
        subject_ids: [
          { required: true, message: '请选择科目', trigger: 'change' }
        ],
        source_id: [
          { required: true, message: '请选择试卷', trigger: 'change' }
        ],
      },
      subjectList: [],
      paperList: [],
      placeList: [],
      paper_year: '',
      source_id: '',
      arrList: [],
      changeId: '',
      user_paper_id: ''

    };
  },
  async created () {
    const { data } = await getDetectionList({ detection_id: this.$route.query.detection_id })
    this.subjectList = data.list
    console.log('fdasgasgafh', window.localStorage.getItem('user_paper_id'))
  },
  mounted () {
    // document.getElementsByClassName('home_right')[0].style.background = '#f6f6f6'
    // document.getElementsByClassName('top_nav')[0].style.background = '#FFFFFF'
    // // document.getElementsByClassName('view_warp')[0].style.background = '#FFFFFF'
    // document.getElementsByClassName('view_warp')[0].style.margin = '24px'
    // document.getElementsByClassName('view_warp')[0].style.marginTop = '34px'
    // document.getElementsByClassName('view_warp')[0].style.height = 'auto'
    // document.getElementsByClassName('el-breadcrumb')[0].style.marginTop = '110px'
    // document.getElementsByClassName('right_select')[0].style.lineHeight = '5'
    // document.getElementsByClassName('left_icon')[0].style.marginLeft = '2px'
    // document.getElementsByClassName('el-breadcrumb')[0].style.marginLeft = '8px'
  },
  methods: {
    goback () {
      this.$router.go(-1)
    },
    async changePlace (val) {
      console.log('val', val)
      this.ruleForm.paper_district_id = ''
      const { data } = await getPaperDistricts({ subject_id: val, detection_id: this.$route.query.detection_id })
      this.placeList = data.list

      this.changeId = val
    },
    async change (val) {
      this.ruleForm.source_id = ''
      const { data } = await getSourceList({ subject_id: this.changeId, detection_id: this.$route.query.detection_id, paper_district_id: val })
      this.paperList = data.list
    },

    async changePaper (val) {

      // console.log('val', val)
      this.paperList.map((item, index) => {
        // console.log('item前', item.source_id + item.source_name)
        // console.log('item前123', val + item.source_name)
        console.log('xiang', val)
        val.map(k=>{
           if (item.source_id + item.source_name == k) {
          console.log('item88', item)
          // this.source_id = item.source_id
          // this.paper_year = Number(item.paper_year)
          this.arrList.push(item)
          // console.log('this.paper_year', this.paper_year)
        }
        })
       
      })
     
     this.arrList =  Array.from(new Set(this.arrList));


    },
    submitForm (formName) {
      this.$refs[formName].model.detection_id = this.$route.query.detection_id
      this.$refs[formName].validate((valid) => {
        this.arrList.map((item) => {
          let params = {
            paper_year: Number(item.paper_year),
            source_id: item.source_id
          }
          console.log('this.$refs[formName].model', this.$refs[formName].model)
          params.detection_id = Number(this.$refs[formName].model.detection_id)
          console.log('12345', this.arrList)
          if (valid) {


            this.$http({
              url: '/api/v1/tang/do_paper',
              method: 'post',
              data:params
            }).then((res) => {
              console.log('ssssssssssssssssssssssss', res.data.list.user_paper_id)
              this.$notify({
                title: '提示',
                message: '提交成功!',
                type: 'success'
              });
              this.$router.go(-1)
              // this.$router.push(
              //   {
              //     path: '/dataAnalysis/viewTestPaper/view',
              //     query: {
              //       user_paper_id: res.data.list.user_paper_id,
              //       // subject_name: this.$refs[formName].model.subject_name,
              //       // template_name: row.template_name
              //     }
              //   })
            }).catch(error => {

              this.$notify({
                title: '提示',
                message: '提交失败!',
                type: 'error'
              });

            });
          } else {
            this.$notify({
              title: '提示',
              message: '提交失败!',
              type: 'error'
            });
            return false;
          }

        })

      });
    },
    //     async postData (params) {
    //       await postDoPaper(params).then((res)=>{
    // // console.log('res',res.data.list.user_paper_id)
    // this.user_paper_id = res.data.list.user_paper_id
    //       })
    //     },
    resetForm (formName) {
      this.$refs[formName].resetFields();
    }
  }
}
</script>

<style lang="scss" scoped>
.main {
  font-family: Regular;
  // padding: 24px 24px 0 24px;
  padding: 12px;
  background: white;
  .title {
    top: 142px;
    left: 256px;
    width: 80px;
    height: 30px;
    // background: rgba(17, 17, 17, 1);
    margin: 8px 0px 30px 12px;
    color: rgba(17, 17, 17, 1);
    font-family: PingFang SC;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
  }
}
::v-deep .el-form-item__label {
  font-size: 14px !important;
}
::v-deep .el-breadcrumb {
  margin-left: 5px;
}
::v-deep .el-button {
  padding: 0;
  width: 60px;
  height: 32px;
  margin-top: 16px;
}
</style>